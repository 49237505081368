var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card3",
    style: {
      backgroundImage: 'url(' + require('@/assets/dashboard/people.png') + ')'
    },
    attrs: {
      "padding": 0,
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("创建SaaS应用"), _c('br'), _vm._v("并制作数据报表")]), _c('Button', {
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "type": "success",
      "size": "large",
      "to": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank"
    }
  }, [_vm._v("创建应用")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };