var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card5",
    attrs: {
      "padding": 0
    }
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("销售数据分析")]), _c('Icon', {
    staticClass: "refresh",
    attrs: {
      "type": "md-refresh",
      "color": "#869ac1",
      "size": "20"
    }
  })], 1), _c('div', {
    staticClass: "title-desc"
  }, [_vm._v("本周销售数据详情图表")]), _c('apexchart', {
    attrs: {
      "type": "line",
      "height": "400",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };