var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dashboard-page"
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 8
    }
  }, [_c('cardLine', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 16
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 6
    }
  }, [_c('card1', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 18
    }
  }, [_c('card2', {
    staticClass: "margin"
  })], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 12
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24
    }
  }, [_c('card3', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 12
    }
  }, [_c('card4', {
    staticClass: "margin",
    attrs: {
      "id": "card4",
      "bordered": false,
      "end-val": 193,
      "backgroundColor": "#6993fe",
      "countColor": "#fff",
      "icon": "ios-apps",
      "iconColor": "#fff",
      "titleColor": "#fff",
      "iconSize": "26",
      "title": "今日新增应用",
      "titleSize": "14px"
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 12
    }
  }, [_c('card4', {
    staticClass: "margin",
    attrs: {
      "id": "card4-2",
      "bordered": false,
      "end-val": 863,
      "countColor": "#3f4255",
      "icon": "md-people",
      "iconColor": "#19be6b",
      "titleColor": "#b5b5c5",
      "iconSize": "34",
      "title": "今日新增用户",
      "titleSize": "14px"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 12
    }
  }, [_c('card5', {
    staticClass: "margin"
  })], 1)], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 8
    }
  }, [_c('cardData', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 16
    }
  }, [_c('cardChart', {
    staticClass: "margin"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };