export default {
  name: "card4",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};