var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card5",
    style: {
      backgroundImage: 'url(' + require('@/assets/dashboard/earth.jpg') + ')'
    },
    attrs: {
      "padding": 0,
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_vm._v("使用移动端App"), _c('br'), _vm._v("创建数据报表")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("立即使用App "), _c('Icon', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "type": "md-arrow-forward",
      "size": "16"
    }
  })], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };