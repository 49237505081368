var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card5",
    attrs: {
      "padding": 0,
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("销售目标")]), _c('Dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('Icon', {
    staticClass: "more",
    attrs: {
      "type": "md-more",
      "color": "#fff",
      "size": "26"
    }
  }), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', [_vm._v("查看详情")]), _c('DropdownItem', [_vm._v("更多操作")])], 1)], 1)], 1), _c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "200",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }), _c('div', {
    staticClass: "white-content"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "title-item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("平均每天销售")]), _c('div', {
    staticClass: "data"
  }, [_vm._v("￥1650.65")])]), _c('div', {
    staticClass: "title-item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("本月目标")]), _c('div', {
    staticClass: "data"
  }, [_vm._v("$63650")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "title-item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("手续费/税收")]), _c('div', {
    staticClass: "data"
  }, [_vm._v("￥13650.13")])]), _c('div', {
    staticClass: "title-item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("净收入")]), _c('div', {
    staticClass: "data"
  }, [_vm._v("$86650.87")])])])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };