var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card1",
    style: {
      backgroundImage: 'url(' + require('@/assets/dashboard/architecture.jpg') + ')'
    },
    attrs: {
      "padding": 0,
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('a', {
    staticClass: "title"
  }, [_vm._v("应用配置")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };