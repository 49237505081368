import cardLine from "./components/cardLine.vue";
import card1 from "./components/card1.vue";
import card2 from "./components/card2.vue";
import card3 from "./components/card3.vue";
import card4 from "@/views/my-components/widget/card1.vue";
import card5 from "./components/card5.vue";
import cardData from "./components/cardData.vue";
import cardChart from "./components/cardChart.vue";
export default {
  name: "dashboard-4",
  components: {
    cardLine,
    card1,
    card2,
    card3,
    card4,
    card5,
    cardData,
    cardChart
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};