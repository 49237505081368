var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-line",
    attrs: {
      "padding": 0
    }
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("本周销售数据")]), _c('div', {
    staticClass: "title-desc"
  }, [_vm._v("本周共计销售数量：128650")])]), _c('Dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('Icon', {
    staticClass: "more",
    attrs: {
      "type": "md-more",
      "color": "#b5b5c1",
      "size": "26"
    }
  }), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', [_vm._v("查看详情")]), _c('DropdownItem', [_vm._v("更多操作")])], 1)], 1)], 1), _c('apexchart', {
    attrs: {
      "type": "area",
      "height": "120",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }), _c('div', {
    staticClass: "pay-content"
  }, [_c('div', {
    staticClass: "pay-item"
  }, [_c('div', {
    staticClass: "pay-item-left"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icon/alipay.png")
    }
  }), _c('div', {
    staticClass: "pay-title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("支付宝")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("同比上周增长￥1456")])])]), _c('div', {
    staticClass: "money"
  }, [_vm._v("+5868￥")])]), _c('div', {
    staticClass: "pay-item"
  }, [_c('div', {
    staticClass: "pay-item-left"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icon/wechatpay.png")
    }
  }), _c('div', {
    staticClass: "pay-title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("微信支付")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("同比上周增长￥156")])])]), _c('div', {
    staticClass: "money"
  }, [_vm._v("+4688￥")])]), _c('div', {
    staticClass: "pay-item"
  }, [_c('div', {
    staticClass: "pay-item-left"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icon/applepay.png")
    }
  }), _c('div', {
    staticClass: "pay-title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Apple Pay")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("同比上周增长￥856")])])]), _c('div', {
    staticClass: "money"
  }, [_vm._v("+1358￥")])]), _c('div', {
    staticClass: "pay-item"
  }, [_c('div', {
    staticClass: "pay-item-left"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icon/yinlian.png")
    }
  }), _c('div', {
    staticClass: "pay-title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("银联/云闪付")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("同比上周增长￥16")])])]), _c('div', {
    staticClass: "money"
  }, [_vm._v("+198￥")])])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };