var zh = require("../../../../libs/zh.json");
export default {
  name: "card4",
  components: {},
  props: {},
  data() {
    return {
      series: [{
        name: "",
        data: []
      }],
      chartOptions: {
        colors: ["#fc8f99", "#fff"],
        chart: {
          type: "bar",
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            borderRadius: 4
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: ["一月", "五月", "六月", "九月", "十月", "十一月", "十二月"]
        },
        yaxis: {
          min: 0
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          marker: {
            show: false
          },
          y: {
            formatter: function (val) {
              return "￥ " + val;
            }
          }
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        name: "净利润",
        data: [44, 55, 57, 56, 61, 58, 63]
      }, {
        name: "营业额",
        data: [76, 85, 101, 98, 87, 105, 91]
      }];
    }
  },
  mounted() {
    this.init();
  }
};