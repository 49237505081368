var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "dashboard4-card2",
    attrs: {
      "padding": 0
    }
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("创建CRM报表报告")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("系统将根据项目自动生成报告")])]), _c('Button', {
    attrs: {
      "type": "info",
      "size": "large",
      "to": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank"
    }
  }, [_vm._v("立即开始")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };